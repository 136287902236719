import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  private baseTitle = '';
  private notificationCount = 0;

  constructor(private title: Title) {}

  setNotificationCount(count: number) {
    if (count !== this.notificationCount) {
      this.notificationCount = count;
      this.updateTitle();
    }
  }

  setBaseTitle(title: string) {
    this.baseTitle = title;
    if (this.notificationCount) {
      this.updateTitle();
    }
  }

  updateTitle() {
    const notificationPart =
      this.notificationCount > 0 ? `(${this.notificationCount}) ` : '';
    this.title.setTitle(`${notificationPart}${this.baseTitle}`);
  }
}
